@import "variables";

.full-screen-popup {
  background-color: rgba(0,0,0,.62);
}

.d-block {
  display: block!important;
}
.modal-custom-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000000;
  display: none;
  outline: 0;
}

.font-weight500{
  font-weight: 500;
}
.width-20{
  width: 20%;
}

.img-detail{
  width: 50%;
  height: auto;
}

.center-div-button-sides {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.center-div-button {
  margin: auto;
  padding: 10px;
}

.float-right {
  float: right;
}

.btn-div {
  width: 7rem;
}

.font-size-header-accordion {
  font-size: 22px;
}

.invalid-data-insert{
  border:2px solid #CD5C5C
}

input[type=checkbox]:checked{
  background-color: $primary !important;
}

.wrapper-offline {
  position: absolute;
  top: 25%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

// Overlay
.overlay-event-menu {
  z-index: 9999999;
}

.divider {
  position: relative;
  background-color: gray;
  display: inline-block;
  margin: 0 0.25%;
  height: 3px;
  width: 100px;
}

.badge-event-selected{
  position: absolute;
  padding: 7px;
  margin-left: -10px;
  margin-top: -10px;
}

//Spinner
.center-spinner-loader{
  margin: auto;
  top:0;
  bottom: -65px;
  left: 0;
  right: 0;
}

.line-through-text {
  text-decoration:line-through;
  color: red;
}

//Timer
.timer-background {
  background-color: transparent;
  opacity: 0.0;
  cursor: default;
  padding: 15px 30px;
  text-decoration: none;
  transition: all 0.5s;
}

.timer-background-green {
  background-color: #63c000;
  color: white;
  cursor: default;
  border-radius: 8px;
  padding: 10px 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.5s;
  border-width: 2px;
  line-height: 1.2;
}

.timer-background-yellow {
  background-color: #E7CA0A;
  color: white;
  cursor: default;
  border-radius: 8px;
  padding: 10px 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.5s;
  border-width: 2px;
  line-height: 1.2;
}

.timer-background-red {
  background-color: #ff4d4d;
  color: white;
  cursor: default;
  border-radius: 8px;
  padding: 10px 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.5s;
  border-width: 2px;
  line-height: 1.2;
}

.thing-one {
  background: yellow;
  width: 25%;
  position: fixed;
}

.title1-modal-event {
  text-align: left;
  color: $primary;
  margin-top: 0px;
  float: left;
  clear: both;
  font-weight: bold;
}

.title2-modal-event {
  text-align: left;
  color: $secondary;
}

.header-list-group-typeticket {
  display: inline-block;
  width: 100%;
}
.list-group-typeticket-right {
  float: right;
}
.list-group-typeticket-left {
  float: left;
}

.text-center-total {
  display: flex;
  justify-content: center;
}

.container-component-cell {
  padding-block: 5px;
  padding-inline: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}


// start view bottom
.view-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  height: 65px;
  box-shadow: 0 0 15px black;
}
// end view bottom

.sticky-view-button{
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 300px;
  right: 30px;
  box-shadow: 0 0 15px #000;
}

@media
only screen and (max-width: 991px){
  .sticky-view-button{
    background-color: white;
    bottom: 0;
    position: sticky;
    left: 0;
    right: 0;
    box-shadow: 0 0 15px #000;
  }
}

// responsive table

@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

  table, thead, tbody, th, td, tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  tr:nth-of-type(odd) {
    background: #eee;
  }

  th {
    background: #333;
    color: white;
    font-weight: bold;
  }

  td, th {
    padding: 6px;
    border: 1px solid #ccc;
    text-align: left;
  }

  //td:nth-of-type(1):before { content: "Giorno"; }


}

.dropdown-toggle::after {
  content: none;
}

.dropdown-inset-contextmenu {
  inset: -50px auto auto -90px !important;
}


/*-------------------------------------start section scroll Horizontal-------------------------------------------*/

.scroll-horizontal{
  display: inline-block;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: default;
  //overflow: hidden;
}

.scroll-horizontal::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 9px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(156, 156, 156, .6);
}

::-webkit-scrollbar {
  /*display: none;*/
}

.scrollList{
  background-color: #ffffff;
  margin-left: 10px;
  padding: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: nowrap;
  scrollbar-width: none;
}

.scrollList::-webkit-scrollbar{
  display: none;
}

.scrollList .scroll-horizontal-category{
  margin-left: 0px;
}

.scrollList .scroll-horizontal-size-350{
  width: 350px;
  margin-left: 15px;
}

.scrollList .scroll-horizontal-size-300{
  width: 300px;
  margin-left: 15px;
}

.scrollList .scroll-horizontal-size-235{
  width: 235px;
  margin-left: 15px;
}

.scrollList .image-cropper img {
  min-width: 0px;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  margin-right: 20px;
  margin-left: 7px;
}

.flex-container::-webkit-scrollbar {
  display: none;
}

/*-------------------------------------end section scroll Horizontal-------------------------------------------*/

.badge-right-day {
  z-index: 1000000;
  background-color: green;
  position: relative;
  top: -33px;
  right: -75px;
}

input:invalid + span::after {
  position: absolute;
  content: "✖";
  padding-left: 5px;
  color: #ac1b1a;
}

input:valid + span::after {
  position: absolute;
  content: "✓";
  padding-left: 5px;
  color: #5da324;
}

.buttonDay {
  width: 85px;
}



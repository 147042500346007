


// Seat map
.map_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 12px 76px 0 rgb(145 158 171 / 10%);
  padding: 20px 50px;
  z-index: 99999999;
  border-radius: 8px;
  overflow: hidden !important;
}

.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seats_row {
  display: flex;
  align-items: center;
}

.seats_vertial_row {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  .seat_circle {
    background-color: $primary;
  }

  .verticle-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.seats_vertial_row .map-row {
  flex-direction: column;
}

.margin-h {
  margin: 0 5px;
}

.empty_space {
  background-color: transparent;
}

.map-row {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
  }
}

.seprator {
  margin: 20px 0;
}

.category_text_sec {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 70px;

  .category_div {
    display: flex;
    min-width: 230px;
    justify-content: space-between;
  }

  .category_heading {
    text-transform: uppercase;
    color: #333;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .category_secondry_color {
    background-color: #f17732 !important;
  }

  .category_color {
    display: inline-block;
    width: 120px;
    height: 20px;
    background-color: $primary;
  }
}

.category-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  #margin-left {
    margin-left: 50px !important;
  }

  .seats_row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .seat_circle {
    background-color: $primary;
  }
}

.category-2-left-row,
.category-2-right-row {
  width: 30px;

  .seat_circle {
    background-color: $primary;
    margin: 1.5px 0;
  }
}

#margin-left {
  margin-left: 100px;
}

.palco_btn {
  cursor: pointer;
  border: 2px solid #333;
  background-color: transparent;
  width: 230px !important;
  margin: 20px auto;
  text-align: center;
}

.react-transform-wrapper {
  margin: 0px auto !important;
  width: 30vw !important;
  height: 40vh !important;
}

.zoom-wrapper {
  width: 100% !important;
}

.zoom-seat-map .btn-area:hover {
  cursor: pointer;
}

.zoom-seat-map {
  position: absolute;
  right: 30px;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 9999;

  .overlay-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .overlay-text {
      color: #fff;
      font-size: 65px;
      width: 50%;
      padding: 0 62px;
    }
  }
}

.zoom-button {
  padding: 7px 10px;
}

#small-font {
  font-size: 6px !important;
}

.booked {
  // background-color: #666666 !important;
  opacity: 1 !important;
}

.selected {
  // background-color: #209f84 !important;
}

.seat-tooltip {
  background-color: #fff !important;
  padding: 0 !important;
  color: #000000;
  opacity: 1 !important;
}

.map-filters {
  position: absolute;
  top: 150px;
  right: 26px;
  z-index: 99999;
  height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s ease;
  filter: drop-shadow(0px 0px 12px rgba(198, 198, 198, 0.25));

  .presense.content-wrapper {
    min-width: 230px;
    min-height: 180px;
    height: fit-content;
    border-radius: 8px;
    background-color: #ffff;
    padding: 8px;


    .body {
      .zone-color {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        margin-right: 5px;
      }

      .role--item {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .item--body {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: rgb(15, 15, 15);
          margin-left: 8px;
        }

        .filter-radio {
          width: 20px !important;
          height: 20px;
          margin: 0 !important;
          accent-color: #171c38
        }
      }
    }

  }
}

.filter-button {
  position: absolute;
  right: 50px;
  top: 125px;
  z-index: 9999999999;
}

.dropdown--visible {
  min-height: 180px;
  opacity: 1;
  height: fit-content;
}

.text-align-right {
  text-align: right;
}


@media (min-width: 1080px) {
  .react-transform-wrapper {
    height: fit-content !important;
  }
}

.size-img-card-payment{
  width: 150px;

}

.align-two-card-flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .align-two-card-flex {
    display: block;
  }
  .react-transform-wrapper {
    margin: 0px auto !important;
    width: 40vw !important;
    height: 40vh !important;
  }
}

.hidden-hour-event {
  visibility: hidden;
}
